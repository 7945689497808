<template>
    <div id="template-session" class="p-0 container-fluid bg-light" style="margin-bottom: 100px">
		<div class="row p-0  m-0 ">
			<div class="col p-0 all_transitions" :style="`max-width: ${show_label ? '250px' : '90px' }`" style="z-index: 10">
				<sidebar bg_color="secondary-dark"></sidebar>
			</div>
			<div class="col p-0 pl-3">
    			<slot></slot>	
			</div>
		</div>
		<ki-alert />
	</div>
</template>

<script>
	import Sidebar from '../components/Sidebar.vue'
	import { mapState } from 'vuex'

	export default {
		name: 'template-session',
		components: {
            'sidebar': Sidebar,
        },
		data() {
			return {

			}
		},
		computed: {
			...mapState(['show_label'])
		},
	}
</script>

<style lang="scss" scoped>
</style>