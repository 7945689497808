<template>
    <aside id="sidebar" 
        class="sidebar bg-custom py-4 all_transitions" 
        :style="[{ 'background-image': `url('${img_bg}')` }, {'width': show_label ? '250px' : '90px'}]">

        <section class="sidebar__logo py-2">
            <img :src="img_logo" class="">
        </section>

        <section class="sidebar__content">


            <div  v-for="(access, index) in getAccess" :key="index"
                :class="['sidebar__content--item text-crop py-2 px-3 my-2', (current_route_name === access.route_name) ? 'sidebar__content--item-active' : '']">
                    <div @click="toggleLabels"
                        style="max-width:16px" 
                        class="py-auto">
                        <img :src="icon_sale" :class="['float-left','img-fluid',(current_route_name === access.route_name) ? '': 'invert-color']">
                    </div>
                    <span @click="redirect(access.route_name)"
                        v-if="show_label" 
                        class="pl-3">
                        {{ access.name }}
                    </span>
            </div> 

            <div  :class="['sidebar__content--item text-crop py-2 px-3 my-2', 
                (current_route_name === 'inventory') ? 'sidebar__content--item-active' : '']">
                    <div @click="toggleLabels" 
                        style="max-width:16px" 
                        class="py-auto">
                        <img :src="icon_inventory" :class="['float-left','img-fluid',(current_route_name === 'inventory') ? '': 'invert-color']">
                    </div>
                    <span  @click="redirect('inventory')"
                        v-if="show_label" 
                        class="pl-3">
                        Inventarios
                    </span>
            </div>

            <div  :class="['sidebar__content--item text-crop py-2 px-3 my-2 d-flex justify-content-start align-items-center', 
                (current_route_name === 'warehouses-menu') ? 'sidebar__content--item-active' : '']">
                    <div @click="toggleLabels" 
                        style="max-width:21px" 
                        class="py-auto">
                        <img :src="icon_warehouses" :class="['float-left','img-fluid',(current_route_name === 'warehouses-menu') ? '': 'invert-color']">
                    </div>
                    <span @click="redirect('warehouses-menu')"
                        v-if="show_label"
                        class="pl-3">
                        Almacenes
                    </span>
            </div>

            <div :class="['sidebar__content--item text-crop py-2 px-3 my-2 d-flex justify-content-start align-items-center', 
                (current_route_name === 'catalog-menu') ? 'sidebar__content--item-active' : '']">
                    <div @click="toggleLabels" 
                        style="max-width:21px" 
                        class="py-auto">
                        <img :src="icon_catalog" :class="['float-left','img-fluid',(current_route_name === 'catalog-menu') ? '': 'invert-color']">
                    </div>
                    <span @click="redirect('catalog-menu')" 
                        v-if="show_label" 
                        class="pl-3">
                        Cátalogos
                    </span>
            </div>

            <div  :class="['sidebar__content--item text-crop py-2 px-3 my-2', 
                (current_route_name === 'reports') ? 'sidebar__content--item-active' : '']">
                    <div @click="toggleLabels"
                        style="max-width:16px" 
                        class="py-auto">
                        <img :src="icon_reports" :class="['float-left','img-fluid',(current_route_name === 'reports') ? '': 'invert-color']">
                    </div>
                    <span @click="redirect('reports')" 
                        v-if="show_label" 
                        class="pl-3">
                        Reportes
                    </span>
            </div>

            <div  class="sidebar__content--item text-crop py-2 px-3 my-2">

                <div @click="toggleLabels"
                    style="max-width:22px" 
                    class="py-auto">
                        <img :src="icon_configuration" :class="['float-left','img-fluid','invert-color']">
                    </div>
                
                <span @click.stop="drop_configuration = !drop_configuration" 
                    v-if="show_label" 
                    class="pl-2">
                    Configuración
                </span>
                <img :src="icon_arrow" 
                    v-if="show_label"
                    :class="['position-absolute all_transitions px-2 ', 
                    drop_configuration ? 'rotate-180' : '']">

                <section :class="['all_transitions_slow sidebar__content--drop', 
                    drop_configuration ? 'sidebar__content--drop-show' : '']" style="padding-left: 12px;">

                    <section class="border-left pl-2">
                    
<!--                        <div @click="redirect('cashregister')"
                            :class="['sidebar__content&#45;&#45;subitem text-crop p-2 my-2',
                            (current_route_name === 'cashregister') ? 'sidebar__content&#45;&#45;subitem-active' : '']">
                                <span class="pl-2">
                                    Caja
                                </span>
                        </div>-->

                        <!-- <div @click="redirect('warehouses')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'warehouses') ? 'sidebar__content--subitem-active' : '']">
                                <span class="pl-2">
                                    Almacenes
                                </span>
                        </div> -->

                        <div @click="redirect('business')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'business') ? 'sidebar__content--subitem-active' : '']">
                                <span class="pl-2">
                                    Empresa
                                </span>
                        </div>

                        <!-- <div @click="redirect('discount')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'discount') ? 'sidebar__content--subitem-active' : '']">
                                <span class="pl-2">
                                    Descuento
                                </span>
                        </div> -->

                        <!-- <div @click="redirect('printedlabels')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'printedlabels') ? 'sidebar__content--subitem-active' : '']">
                                <span class="pl-2">
                                    Etiquetas Impresas
                                </span>
                        </div> -->

                        <!-- <div @click="redirect('ticket')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'tickets') ? 'sidebar__content--subitem-active' : '']">
                                <span class="pl-2">
                                    Tickets
                                </span>
                        </div> -->

                        <!-- <div @click="redirect('users')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'users') ? 'sidebar__content--subitem-active' : '']">
                                <span class="pl-2">
                                    Usuarios
                                </span>
                        </div> -->

                        <!-- <div @click="redirect('branch')" 
                            :class="['sidebar__content--subitem text-crop p-2 my-2', 
                            (current_route_name === 'branch') ? 'sidebar__content--subitem-active' : '']">
                                <span  class="pl-2">
                                    Sucursales
                                </span>
                        </div> -->

                    </section>

                </section>
            </div>
            
            <div  :class="['sidebar__content--item text-crop py-3 px-3 my-2 border-top border-bottom', 
                (current_route_name === 'logout') ? 'sidebar__content--item-active' : '']"
                :style="[{'borderRadius': '0px'}]">
                    <img @click="toggleLabels" 
                        :src="icon_logout" 
                        class="invert-color float-left pr-2">
                    <span v-if="show_label"
                        @click="logout">
                        Cerrar Sesión
                    </span>
            </div>
        </section>
    </aside>
</template>

<script>
    // IMAGES
    import img_logo from 'ASSETS/images/sidebar/img_logo.png'
    import img_bg from 'ASSETS/images/background.png'

    //ICONS
    import icon_sale from '../assets/icons/icon_sale.png'
    import icon_inventory from '../assets/icons/icon_inventory.png'
    import icon_reports from '../assets/icons/icon_reports.png'
    import icon_configuration from '../assets/icons/icon_configuration.png'
    import icon_logout from '../assets/icons/icon_logout.png'
    import icon_arrow from '../assets/icons/icon_arrow.png'
    import icon_warehouses from '../assets/icons/icon_warehouse.png'
    import icon_catalog from '../assets/icons/icon_book.png'

    //hooks
    import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
    name: 'ki-sidebar',
    data() {
        return {
            img_logo,
            img_bg,
            icon_sale,
            icon_inventory,
            icon_reports,
            icon_configuration,
            icon_logout,
            drop_configuration: false,
            icon_arrow,
            icon_warehouses,
            icon_catalog
        };
    },
    computed: {
        current_route_name() {
            return this.$route.name;
        },
        current_env() {
            return CONFIG.env
        },
        ...mapState(['show_label']),
        ...mapGetters(['getSession', 'getAccess'])
    },
    methods: {
        redirect(name_route) {
            console.log(name_route);
            if(this.$route.name !== name_route) {
                window.scrollTo(0, 0)

                this.$router.push({
                    name: name_route
                })
            }
        },
        ...mapMutations(['toggleLabels']),
        logout() {
            sessionStorage.clear()
            this.$store.commit('setLogin', false)
            this.$store.commit('setSession', null)
            this.redirect('login')
        }
    },
    created(){
        
    }
    };
</script>

<style lang="scss" scoped>
    .sidebar {
        position: fixed;
        top: 0px;
        bottom: 0px;
        overflow-y: scroll;


        &__logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__content {
            padding: 20px 15px;

            &--item {
                max-width: 210px;
            }
            &--subitem {
                max-width: 155px;
            }

            &--item, &--subitem {
                cursor: pointer;
                border-radius: 12px;
                color: white !important;
                
                -webkit-transition: background-color .8s !important;
                -moz-transition: background-color .8s !important;
                -ms-transition: background-color .8s !important;
                -o-transition: background-color .8s !important;
                transition: background-color .8s !important;
            }
            &--item-active, &--subitem-active {
                background-color: white;
                color: black !important;
            }

            &--drop {
                height: 0;
                opacity: 0;
                clear: both;
                overflow: hidden;
            }
            &--drop-show {
                opacity: 1;
                height: auto;
            }

        }
    }
</style>