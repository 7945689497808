var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"sidebar bg-custom py-4 all_transitions",style:([{ 'background-image': `url('${_vm.img_bg}')` }, {'width': _vm.show_label ? '250px' : '90px'}]),attrs:{"id":"sidebar"}},[_c('section',{staticClass:"sidebar__logo py-2"},[_c('img',{attrs:{"src":_vm.img_logo}})]),_c('section',{staticClass:"sidebar__content"},[_vm._l((_vm.getAccess),function(access,index){return _c('div',{key:index,class:['sidebar__content--item text-crop py-2 px-3 my-2', (_vm.current_route_name === access.route_name) ? 'sidebar__content--item-active' : '']},[_c('div',{staticClass:"py-auto",staticStyle:{"max-width":"16px"},on:{"click":_vm.toggleLabels}},[_c('img',{class:['float-left','img-fluid',(_vm.current_route_name === access.route_name) ? '': 'invert-color'],attrs:{"src":_vm.icon_sale}})]),(_vm.show_label)?_c('span',{staticClass:"pl-3",on:{"click":function($event){return _vm.redirect(access.route_name)}}},[_vm._v(" "+_vm._s(access.name)+" ")]):_vm._e()])}),_c('div',{class:['sidebar__content--item text-crop py-2 px-3 my-2', 
                (_vm.current_route_name === 'inventory') ? 'sidebar__content--item-active' : '']},[_c('div',{staticClass:"py-auto",staticStyle:{"max-width":"16px"},on:{"click":_vm.toggleLabels}},[_c('img',{class:['float-left','img-fluid',(_vm.current_route_name === 'inventory') ? '': 'invert-color'],attrs:{"src":_vm.icon_inventory}})]),(_vm.show_label)?_c('span',{staticClass:"pl-3",on:{"click":function($event){return _vm.redirect('inventory')}}},[_vm._v(" Inventarios ")]):_vm._e()]),_c('div',{class:['sidebar__content--item text-crop py-2 px-3 my-2 d-flex justify-content-start align-items-center', 
                (_vm.current_route_name === 'warehouses-menu') ? 'sidebar__content--item-active' : '']},[_c('div',{staticClass:"py-auto",staticStyle:{"max-width":"21px"},on:{"click":_vm.toggleLabels}},[_c('img',{class:['float-left','img-fluid',(_vm.current_route_name === 'warehouses-menu') ? '': 'invert-color'],attrs:{"src":_vm.icon_warehouses}})]),(_vm.show_label)?_c('span',{staticClass:"pl-3",on:{"click":function($event){return _vm.redirect('warehouses-menu')}}},[_vm._v(" Almacenes ")]):_vm._e()]),_c('div',{class:['sidebar__content--item text-crop py-2 px-3 my-2 d-flex justify-content-start align-items-center', 
                (_vm.current_route_name === 'catalog-menu') ? 'sidebar__content--item-active' : '']},[_c('div',{staticClass:"py-auto",staticStyle:{"max-width":"21px"},on:{"click":_vm.toggleLabels}},[_c('img',{class:['float-left','img-fluid',(_vm.current_route_name === 'catalog-menu') ? '': 'invert-color'],attrs:{"src":_vm.icon_catalog}})]),(_vm.show_label)?_c('span',{staticClass:"pl-3",on:{"click":function($event){return _vm.redirect('catalog-menu')}}},[_vm._v(" Cátalogos ")]):_vm._e()]),_c('div',{class:['sidebar__content--item text-crop py-2 px-3 my-2', 
                (_vm.current_route_name === 'reports') ? 'sidebar__content--item-active' : '']},[_c('div',{staticClass:"py-auto",staticStyle:{"max-width":"16px"},on:{"click":_vm.toggleLabels}},[_c('img',{class:['float-left','img-fluid',(_vm.current_route_name === 'reports') ? '': 'invert-color'],attrs:{"src":_vm.icon_reports}})]),(_vm.show_label)?_c('span',{staticClass:"pl-3",on:{"click":function($event){return _vm.redirect('reports')}}},[_vm._v(" Reportes ")]):_vm._e()]),_c('div',{staticClass:"sidebar__content--item text-crop py-2 px-3 my-2"},[_c('div',{staticClass:"py-auto",staticStyle:{"max-width":"22px"},on:{"click":_vm.toggleLabels}},[_c('img',{class:['float-left','img-fluid','invert-color'],attrs:{"src":_vm.icon_configuration}})]),(_vm.show_label)?_c('span',{staticClass:"pl-2",on:{"click":function($event){$event.stopPropagation();_vm.drop_configuration = !_vm.drop_configuration}}},[_vm._v(" Configuración ")]):_vm._e(),(_vm.show_label)?_c('img',{class:['position-absolute all_transitions px-2 ', 
                    _vm.drop_configuration ? 'rotate-180' : ''],attrs:{"src":_vm.icon_arrow}}):_vm._e(),_c('section',{class:['all_transitions_slow sidebar__content--drop', 
                    _vm.drop_configuration ? 'sidebar__content--drop-show' : ''],staticStyle:{"padding-left":"12px"}},[_c('section',{staticClass:"border-left pl-2"},[_c('div',{class:['sidebar__content--subitem text-crop p-2 my-2', 
                            (_vm.current_route_name === 'business') ? 'sidebar__content--subitem-active' : ''],on:{"click":function($event){return _vm.redirect('business')}}},[_c('span',{staticClass:"pl-2"},[_vm._v(" Empresa ")])])])])]),_c('div',{class:['sidebar__content--item text-crop py-3 px-3 my-2 border-top border-bottom', 
                (_vm.current_route_name === 'logout') ? 'sidebar__content--item-active' : ''],style:([{'borderRadius': '0px'}])},[_c('img',{staticClass:"invert-color float-left pr-2",attrs:{"src":_vm.icon_logout},on:{"click":_vm.toggleLabels}}),(_vm.show_label)?_c('span',{on:{"click":_vm.logout}},[_vm._v(" Cerrar Sesión ")]):_vm._e()])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }